import type {EnvVarValue, IConfig, ReactEnvVar} from 'types/app'

export const getEnvVar = (key: ReactEnvVar): EnvVarValue => process.env[key]

const CONFIG: IConfig = {
  apiUrl: {
    prod: getEnvVar('REACT_APP_CRM_API_PROD'),
    play: getEnvVar('REACT_APP_CRM_API_PLAY'),
    dev: getEnvVar('REACT_APP_CRM_API_DEV'),
    local: getEnvVar('REACT_APP_CRM_API_LOCAL')
  },
  cookiesPrefix: {
    front: 'front.',
    erp: 'im_crm_'
  },
  erpEnv: getEnvVar('REACT_APP_ERP_ENV'),
  actions: {
    upsert: getEnvVar('REACT_APP_ERP_ACTION_ID_UPSERT'),
    abandon: getEnvVar('REACT_APP_ERP_ACTION_ID_ABANDON')
  },
  datadog: {
    applicationId: getEnvVar('REACT_APP_DD_APP_KEY'),
    clientToken: getEnvVar('REACT_APP_DD_CT_KEY'),
    branchName: getEnvVar('REACT_APP_BRANCH_NAME'),
    commitSha: getEnvVar('REACT_APP_COMMIT_SHA')
  }
} as const

export default CONFIG
