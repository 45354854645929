import {IAppContext, useAppContext} from 'providers/appProvider'
import React, {useState} from 'react'
import LogoutUser from './logoutUser'
import './_formView.scss'
import StepNew from './steps/stepNew'
import StepMql from './steps/stepMql'
import AbandonmentModal from 'components/modals/abandonmentModal'
import SuccessReasonModal from 'components/modals/successReasonModal'
import StepSql from './steps/stepSql'
import StepAbandoned from './steps/stepAbandoned'
import StepOrder from './steps/stepOrder'
import StepCpo from './steps/stepCpo'
import AttendantReasonModal from 'components/modals/attendantModal'

interface IFormViewProps {
  resetRequest: () => void
}

const FormView = ({resetRequest}: IFormViewProps) => {
  const {request, selects, hasProject}: IAppContext = useAppContext()

  if (!selects) return <></>

  const [abandonModal, setAbandonModal] = useState(false)
  const [successReasonModal, setSuccessReasonModal] = useState(false)
  const [attendantModal, setAttendantModal] = useState(false)

  const renderStep = () => {
    switch (request.step) {
      case 'new':
        return <StepNew />
      case 'mql':
        return (
          <StepMql
            setAbandonModal={setAbandonModal}
            setSuccessReasonModal={setSuccessReasonModal}
          />
        )
      case 'sql':
        return <StepSql setAttendantModal={setAttendantModal} />
      case 'abandoned':
        return <StepAbandoned resetRequest={resetRequest} />
      case 'order':
        return <StepOrder />
      case 'cpo':
        return <StepCpo />
      default:
        return <></>
    }
  }

  return (
    <>
      <div
        id='sc-form'
        className={`container ${(abandonModal || successReasonModal) && 'no-scroll'}`}
      >
        <LogoutUser />
        {hasProject && renderStep()}
        {abandonModal && <AbandonmentModal showModal={setAbandonModal} />}
        {successReasonModal && <SuccessReasonModal showModal={setSuccessReasonModal} />}
        {attendantModal && <AttendantReasonModal showModal={setAttendantModal} />}
      </div>
    </>
  )
}

export default FormView
