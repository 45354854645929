import React, {CSSProperties} from 'react'
import LogoutUser from 'views/singleConversation/logoutUser'
import ClipLoader from 'react-spinners/ClipLoader'

const override: CSSProperties = {
  display: 'block',
  margin: '100px auto',
  borderColor: '#367fee'
}

interface ILoaderProps {
  loading?: boolean
}

const Loader = ({loading = true}: ILoaderProps) => {
  return (
    <div id='sc-form' className='container'>
      <p>Déconnectez-vous si vous rencontrez une erreur</p>
      <LogoutUser />
      <div className='content'>
        <ClipLoader
          loading={loading}
          size={30}
          aria-label='Loading'
          data-testid='loader'
          cssOverride={override}
        />
      </div>
    </div>
  )
}

export default Loader
