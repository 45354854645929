import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {Button, VisualSizesEnum} from '@frontapp/ui-kit'

interface IRedoButtonProps {
  resetRequest: () => void
}

const RedoButton = (props: IRedoButtonProps) => {
  const {resetRequest} = props

  return (
    <div className={'items-center'}>
      <Button
        type={'tertiary'}
        size={VisualSizesEnum.LARGE}
        className={'btn btn-cancel gap-sides'}
        onClick={() => {
          resetRequest()
        }}
      >
        <FontAwesomeIcon icon={['fas', 'redo']} className='space-between-icon-text' />
        Faire une nouvelle demande
      </Button>
    </div>
  )
}

export default RedoButton
