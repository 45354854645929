import Alert from 'components/form/alert'
import {isNoFollowUpAbandonment} from 'helpers/request'
import {IErpRequest} from 'queries/types'

interface IFormAlertProps {
  request: IErpRequest
}
const FormAlert = (props: IFormAlertProps) => {
  const {request} = props
  if (request.step === 'new')
    return <Alert color='warning' message='Nouvelle demande' icon='Star' />

  if (request.step === 'mql') {
    return <Alert color='success' message='Rendez-vous à prendre' icon='StarFilled' />
  }
  if (request.step === 'sql') {
    return <Alert color='success' inversedColors message='Devis à faire signer' icon='StarFilled' />
  }
  if (request.step === 'order') {
    return <Alert color='success' inversedColors message='Devis signé' icon='CheckmarkCircle' />
  }
  if (request.step === 'abandoned') {
    return (
      <Alert
        color='danger'
        message={isNoFollowUpAbandonment(request) ? 'Projet sans suite' : 'Demande abandonnée'}
        icon='ArchiveFilled'
      />
    )
  }

  return <> </>
}

export default FormAlert
