import {SingleConversationContext} from '@frontapp/plugin-sdk'
import {Button, Textarea, VisualSizesEnum} from '@frontapp/ui-kit'
import Checkbox from 'components/form/checkbox'
import {isNoFollowUpAbandonment} from 'helpers/request'
import {getSelectOptionLabel} from 'helpers/select'
import {formatDate, formatDateTime} from 'helpers/utils'
import {useUpsertNote} from 'hooks/custom-queries'
import {noteTemplate} from 'lib/constants'
import {SelectKey} from 'lib/enums'
import {IAppContext, useAppContext} from 'providers/appProvider'
import {useFrontContext} from 'providers/frontContext'
import {useMemo, useState} from 'react'
import {ClipLoader} from 'react-spinners'

interface IRecapProps {
  noteEditable?: boolean
}

const Recap = ({noteEditable = false}: IRecapProps) => {
  const frontContext = useFrontContext() as SingleConversationContext
  const {request, setRequest, selects, regionItems, agencyItems}: IAppContext = useAppContext()

  const {isLoading, upsert} = useUpsertNote(request._id)

  const [noteEdited, setNoteEdited] = useState(false)

  if (!selects) return <></>

  const updateNote = (value) => {
    const updatedRequest = {
      ...request,
      note: value,
      notes: request.notes?.map((note) => {
        if (note.type === 'reception') {
          return {...note, data: value}
        }
        return note
      })
    }

    setRequest(updatedRequest, frontContext.conversation.id)
    setNoteEdited(true)
  }

  const note = useMemo<string>(() => {
    return request?.notes?.filter((note) => note.type === 'reception')?.[0]?.data || request?.note
  }, [request?.notes, request?.note])

  const defunctDOD = useMemo(() => {
    if (!request.defunct?.dod) return ''

    return formatDate(request.defunct.dod)
  }, [request.defunct?.dod])

  const appointmentDate = useMemo(() => {
    if (!request.appointmentDate) return

    return formatDateTime(request.appointmentDate)
  }, [request.appointmentDate])

  const attendant = useMemo(() => {
    if (typeof request.attendant === 'string') return

    return request?.attendant?.name
  }, [request.attendant])

  return (
    <>
      <h2 className='section-title'>
        1.{' '}
        <span className='section-title-label'>
          {selects[SelectKey.CONCERN_FORM_SECTIONS][0].label || 'Sa situation et localisation'}
        </span>
      </h2>
      <div className='block'>
        {getSelectOptionLabel(selects[SelectKey.PROJECT_TYPE], request.type)}
        <br></br>
        {getSelectOptionLabel(selects[SelectKey.OTHER_SITUATION], request?.situationInfo)}
      </div>

      {/* request.house is undefined when Autre>Prévoyance but yout don't want to display hors zone*/}
      {request.type !== 'noInfo' && request.situationInfo !== 'cpo' && (
        <>
          {!request.house && <div className='block'>Hors zone</div>}
          {request.house && (
            <>
              <div className='block'>
                {getSelectOptionLabel(regionItems, request.house?._id || request.house)}
              </div>
              <div className='block'>
                {getSelectOptionLabel(agencyItems, request.agency?._id || request.agency)}
              </div>
            </>
          )}
        </>
      )}

      {request.situationDetails && <div className='block'>{request.situationDetails}</div>}

      {request.contactReached !== 'error' && request.house && (
        <>
          <h2 className='section-title'>
            2.{' '}
            <span className='section-title-label'>
              {selects[SelectKey.CONCERN_FORM_SECTIONS][1]?.label || 'Ses informations'}
            </span>
          </h2>
          {request.contact?.lastname && <div className='block'>{request.contact.lastname}</div>}
          {request.contact?.firstname && <div className='block'>{request.contact.firstname}</div>}
          {request.contact?.phone && <div className='block'>{request.contact.phone}</div>}
          {request.contact?.email && <div className='block'>{request.contact.email}</div>}
          {request.relationship && (
            <div className='block'>
              {
                selects[SelectKey.RELATIONSHIPS]?.find(({value}) => value === request.relationship)
                  ?.label
              }
            </div>
          )}

          {request.defunct && (
            <h2 className='section-title'>
              3.{' '}
              <span className='section-title-label'>
                {selects[SelectKey.CONCERN_FORM_SECTIONS][2]?.label || 'Informations du défunt'}
              </span>
            </h2>
          )}
          {request.defunct?.lastname && <div className='block'>{request.defunct.lastname}</div>}
          {request.defunct?.firstname && <div className='block'>{request.defunct.firstname}</div>}
          {request.defunct?.gender && (
            <div className='block'>
              {
                selects[SelectKey.CIVILITY]?.find(({value}) => value === request.defunct?.gender)
                  ?.label
              }
            </div>
          )}
          {!!defunctDOD && <div className='block'>{defunctDOD}</div>}
          {request.defunct?.ageRange && (
            <div className='block'>
              {
                selects[SelectKey.AGE_RANGE]?.find(({value}) => value === request.defunct?.ageRange)
                  ?.label
              }
            </div>
          )}

          {request.house !== 'other' &&
            request.step === 'abandoned' &&
            request.type !== 'noInfo' &&
            isNoFollowUpAbandonment(request) && (
              <>
                <h6 className='space-below bold-black'>😢 Pas de suite</h6>
                <Checkbox
                  items={selects[SelectKey.ABANDONMENT_REASON]}
                  value={request.abandonmentReason}
                  isSummary={true}
                />
                {request.customAbandonmentReason && (
                  <div className='block'>{request.customAbandonmentReason}</div>
                )}
              </>
            )}

          {!request.isAbandoned && (note || noteEditable) && (
            <>
              <div className='note-title'>
                <h2 className='section-title'>
                  <span className='section-title-label'>Notes</span>
                </h2>
                {noteEditable && noteEdited && (
                  <Button
                    type={'primary'}
                    size={VisualSizesEnum.LARGE}
                    className={`btn btn-small`}
                    onClick={() => {
                      upsert(note).then(() => {
                        setNoteEdited(false)
                      })
                    }}
                  >
                    <ClipLoader
                      loading={isLoading}
                      size={3}
                      aria-label='Loading'
                      data-testid='loader'
                    />
                    {isLoading ? '' : 'Enregistrer la note'}
                  </Button>
                )}
              </div>
              {!noteEditable && <div className='block'>{note || ''}</div>}
              {noteEditable && (
                <div className='block'>
                  <Textarea
                    value={note || noteTemplate}
                    placeholder={'Notes'}
                    onChange={updateNote}
                    rows={12}
                  />
                </div>
              )}
            </>
          )}

          {(request.step === 'order' || request.step === 'sql') && (
            <>
              <h6 className='space-below bold-black'>👏 RDV pris</h6>
              {appointmentDate && (
                <div className='block gap-top'>Date du RDV: {appointmentDate}</div>
              )}
              {attendant && <div className='block'>Accompagnant(e): {attendant}</div>}
            </>
          )}
        </>
      )}
    </>
  )
}

export default Recap
