import {TokenCookie} from 'lib/enums'
import Cookies from 'lib/cookies'
import config from 'config'

const disconnect = () => {
  const cookies = new Cookies(config.cookiesPrefix.front)
  cookies.remove(TokenCookie.KEY)
  setTimeout(() => {
    window.location.reload()
  }, 400)
}

const LogoutUser = () => {
  return (
    <div>
      <div className='deconnexion-link' onClick={disconnect}>
        Déconnexion
      </div>
    </div>
  )
}

export default LogoutUser
