export function getSelectOptionLabel(options, key) {
  return options.find((item) => item.value === key)?.label
}

/**
 * When a user select a region (request.house) in the first select, we only want to display the agency/agencies linked to this region.
 * @param requestHouse - the selected region
 * @param agencyItems - the list of all agencies
 * @returns linkedAgencyItems - the list of agencies linked to the selected region
 */
export function getLinkedAgencies(requestHouse, agencyItems) {
  return agencyItems.filter((agencyItem) => agencyItem.linkedRegion === requestHouse)
}
