import * as yup from 'yup'
import 'yup-phone'

/**
 * @spec - empty is allowed but not valid
 * @param value - phone number
 * @returns boolean
 */
const validatePhone = (value?: string): boolean => {
  if (!value) return false

  try {
    yup.string().phone('FR').validateSync(value)
    return true
  } catch (error) {
    return false
  }
}

// base schema
export const formSchema = yup.object().shape({
  step: yup.string(),
  type: yup.string().required(),
  house: yup.string().when('step', {
    is: 'new',
    then: yup.string().required()
  }),
  agency: yup.mixed().when(['step', 'house', 'appointmentAtHome'], {
    is: (step, house, appointmentAtHome) =>
      step === 'sql' && house !== 'other' && !appointmentAtHome,
    then: yup.mixed().required(),
    otherwise: yup.mixed()
  }),
  contact: yup.object().shape({
    lastname: yup.string(),
    firstname: yup.string(),
    phone: yup.string().test('phone-validation', 'Invalid phone number', validatePhone),
    email: yup.string().email()
  }),
  situationDetails: yup.string(),
  note: yup.string(),
  abandonmentReason: yup.string().when('step', {
    is: 'abandoned',
    then: yup.string().required()
  }),
  customAbandonmentReason: yup.string().when('abandonmentReason', {
    is: 'other',
    then: yup.string().required()
  }),
  appointmentDate: yup.date().when(['step'], {
    is: (step) => step === 'sql',
    then: yup.date().required(),
    otherwise: yup.date().optional()
  }),
  appointmentAtHome: yup.bool().when(['step'], {
    is: (step) => step === 'sql',
    then: yup.bool().required(),
    otherwise: yup.bool().optional()
  })
})

export const formSchemaDisplayTypeOther = yup.object().shape({
  type: yup.string().required(),
  situationInfo: yup.string().required(),
  situationDetails: yup.string().when('situationInfo', {
    is: 'errorOrOffTopic',
    then: yup.string().required(),
    otherwise: yup.string().optional()
  })
})

export const formSchemaUpcomingDeathNoHome = yup.object().shape({
  type: yup.string().required(),
  house: yup.string(),
  situationDetails: yup.string().required()
})

//PartnershipView
export const formSchemaPartnership = yup.object().shape({
  house: yup.string().required(),
  agency: yup.string(),
  partnershipContact: yup.object().shape({
    lastname: yup.string(),
    firstname: yup.string()
  })
})
