import {useState} from 'react'
import Alert from 'components/form/alert'
import {IAppContext, useAppContext} from 'providers/appProvider'
import {DatePicker, Textarea} from '@frontapp/ui-kit'
import {RequestField} from 'queries/types'
import {SelectKey} from 'lib/enums'
import Select from 'components/form/select'
import {getAutoAbandonmentReason} from 'helpers/request'
import Input from 'components/form/input'
import {useFrontContext} from 'providers/frontContext'
import {SingleConversationContext} from '@frontapp/plugin-sdk'
import {useUpsertRequest} from 'hooks/custom-queries'
import ContinueButton from '../continueBtn'
import {useForm} from 'hooks/use-form'
import apiClient from 'queries'
import {getTodayDate} from 'helpers/utils'
import DateInput from 'components/form/date-input'
import {noteTemplate} from 'lib/constants'

const StepNew = () => {
  const {request, setRequest, selects, regionItems, linkedAgencyItems, hasProject}: IAppContext =
    useAppContext()
  const frontContext = useFrontContext() as SingleConversationContext
  const isFormValid: boolean = useForm(request)
  const upsertRequest = useUpsertRequest(frontContext.conversation.id)
  const {createCommentRequest} = apiClient()
  const [isLoading, setIsLoading] = useState(false)

  const setForm = (key: RequestField, subKey?: string) => (value) => {
    // reset agency and house when Other is selected
    if (key === 'type' && value === 'noInfo') {
      request.agency = undefined
      request.house = undefined
    }

    //reset situationInfo if type changes
    if (key === 'type' && value !== 'noInfo') {
      request.situationInfo = undefined
    }

    // when !house is selected, reset some fields
    if (key === 'house' && value === 'other' && request.situationInfo !== 'cpo') {
      request.agency = undefined
      request.situationInfo = undefined
    }

    const updatedRequest = {
      ...request,
      [key]: subKey ? {...request[key], [subKey]: value} : value
    }

    setRequest(updatedRequest, frontContext.conversation.id)
  }

  const onSave = () => {
    setIsLoading(true)
    const updatedRequest = {
      ...request,
      isFirstStepManual: true,
      house: request.house === 'other' ? undefined : request.house // ERP doesn't know 'other' value so we change it to `undefined`
    }

    const autoAbandonmentReason = getAutoAbandonmentReason(updatedRequest)

    if (autoAbandonmentReason) {
      updatedRequest.step = 'abandoned'
      updatedRequest.isAbandoned = true
      updatedRequest.abandonmentReason = autoAbandonmentReason.abandonmentReason
      createCommentRequest({
        conversationId: frontContext?.conversation.id,
        comment: {
          body: `
              Abandon automatique le: ${getTodayDate()}
              Utilisateur: ${frontContext.teammate.name}
              ${autoAbandonmentReason.message}
              `
        }
      })
    } else if (request.type === 'noInfo' && request.situationInfo === 'cpo') {
      updatedRequest.step = 'cpo'
    } else {
      updatedRequest.step = 'mql'
    }

    upsertRequest.upsert(updatedRequest).then((data) => {
      setRequest(data)
      return data
    })
  }

  return (
    <>
      <div className='content step-new'>
        <Alert color='warning' message='Nouvelle demande' icon='Star' />

        <h2 className='section-title'>
          1.{' '}
          <span className='section-title-label'>
            {selects[SelectKey.CONCERN_FORM_SECTIONS][0].label}
          </span>
        </h2>
        <Select
          items={selects[SelectKey.PROJECT_TYPE]}
          value={request.type}
          set={setForm('type')}
          placeholder={'Situation *'}
          position={'bottom-start'}
        />
        {request.type === 'noInfo' && (
          <Select
            items={selects[SelectKey.OTHER_SITUATION]}
            value={request.situationInfo}
            set={setForm('situationInfo')}
            placeholder={'Préciser la situation *'}
            position={'bottom-start'}
          />
        )}

        {(request.type !== 'noInfo' ||
          (request.type === 'noInfo' && request.situationInfo === 'cpo')) && (
          <>
            <Select
              items={regionItems}
              value={request.house?._id || request.house}
              set={setForm('house')}
              placeholder={'Région concernée *'}
              position={'bottom-start'}
            />

            {request.house !== 'other' && (
              <>
                <Select
                  items={linkedAgencyItems}
                  value={request.agency?._id || request.agency}
                  set={setForm('agency')}
                  placeholder={'Maison responsable'}
                  position={'bottom-start'}
                />
                <h2 className='section-title'>
                  3.{' '}
                  <span className='section-title-label'>
                    {selects[SelectKey.CONCERN_FORM_SECTIONS][1].label}
                  </span>
                </h2>
                <Input
                  type={'text'}
                  value={request.contact?.lastname || ''}
                  placeholder={'Nom'}
                  onChange={setForm('contact', 'lastname')}
                />
                <Input
                  type={'text'}
                  value={request.contact?.firstname || ''}
                  placeholder={'Prénom'}
                  onChange={setForm('contact', 'firstname')}
                />
                <Input
                  type={'phone'}
                  value={request.contact?.phone || ''}
                  placeholder={'Numéro de téléphone (06) *'}
                  onChange={setForm('contact', 'phone')}
                />
                <Input
                  type={'text'}
                  value={request.contact?.email || ''}
                  placeholder={'Email'}
                  onChange={setForm('contact', 'email')}
                />
                <Select
                  items={selects[SelectKey.RELATIONSHIPS]}
                  value={request.relationship}
                  set={setForm('relationship')}
                  placeholder={'Relation envers le défunt'}
                />

                <h2 className='section-title'>
                  4.{' '}
                  <span className='section-title-label'>
                    {selects[SelectKey.CONCERN_FORM_SECTIONS][2].label}
                  </span>
                </h2>
                <Input
                  type={'text'}
                  value={request.defunct?.lastname || ''}
                  placeholder={'Nom'}
                  onChange={setForm('defunct', 'lastname')}
                />
                <Input
                  type={'text'}
                  value={request.defunct?.firstname || ''}
                  placeholder={'Prénom'}
                  onChange={setForm('defunct', 'firstname')}
                />
                <Select
                  items={selects[SelectKey.CIVILITY]}
                  value={request.defunct?.gender}
                  set={setForm('defunct', 'gender')}
                  placeholder={'Civilité'}
                />
                {request.type === 'passedDeath' && (
                  <DateInput
                    placeholder='Date de décès'
                    value={request.defunct?.dod}
                    onChange={(input) => {
                      setForm('defunct', 'dod')(input)
                    }}
                  />
                )}
                <Select
                  items={selects[SelectKey.AGE_RANGE]}
                  value={request.defunct?.ageRange}
                  set={setForm('defunct', 'ageRange')}
                  placeholder={"Tranche d'âge"}
                />
              </>
            )}

            {request.situationInfo !== 'other' && request.house !== 'other' && (
              <>
                <h6>Notes</h6>
                <Textarea
                  value={request.note?.replaceAll('<br />', '\n') || noteTemplate}
                  placeholder={'Notes'}
                  onChange={setForm('note')}
                  rows={12}
                />
              </>
            )}
          </>
        )}

        {((request.type === 'noInfo' && request.situationInfo !== 'cpo') ||
          request.house === 'other') && (
          <Textarea
            value={request.situationDetails || ''}
            placeholder={
              'Précisions' +
              (request.situationInfo === 'errorOrOffTopic'
                ? ' *'
                : request.house === 'other'
                ? ' sur la zone *'
                : '')
            }
            onChange={setForm('situationDetails')}
            rows={4}
          />
        )}
      </div>
      <div className={'bottom-fixed'}>
        {request.error && (
          <>
            <div className='alert-danger-bottom'>
              <Alert
                color='danger'
                inversedColors
                message={`Erreur lors de l'enregistrement : ${request.error}`}
              />
            </div>
          </>
        )}
        <div className={'items-center'}>
          <ContinueButton
            isDisabled={!hasProject || !isFormValid}
            onClick={onSave}
            request={request}
            loading={isLoading}
          />
        </div>
      </div>
    </>
  )
}

export default StepNew
