import {IErpRequest, TypePrecision} from 'queries/types'

const createReasonMessage = (reason, details) =>
  `Raison: ${reason}${details ? ' \n Précisions: ' + details : ''}`

const reasonMessages = {
  [TypePrecision.ErrorOrOffTopic]: (details?: string) =>
    createReasonMessage('Erreur ou hors-sujet', details),
  [TypePrecision.TributePage]: (details?: string) =>
    createReasonMessage("Espace d'hommage", details),
  [TypePrecision.Journalist]: (details?: string) => createReasonMessage('Journaliste', details),
  [TypePrecision.Candidate]: (details?: string) => createReasonMessage('Candidat', details),
  [TypePrecision.Canvassing]: (details?: string) => createReasonMessage('Démarchage', details),
  [TypePrecision.OtherFH]: (details?: string) =>
    createReasonMessage('Autre prestation funéraire', details),
  [TypePrecision.FamilyManyContacts]: (details?: string) =>
    createReasonMessage('Multiple contact d’une famille', details),
  [TypePrecision.NeverHadByPhone]: (details?: string) =>
    createReasonMessage('Jamais eu au téléphone', details),
  [TypePrecision.OtherTeam]: (details?: string) =>
    createReasonMessage("Cherchait à joindre l'équipe Maison", details)
}

export function getAutoAbandonmentReason(
  request: IErpRequest
): {message: string; abandonmentReason: string} | undefined {
  // noInfo requests never contains house/agency information
  if (!request.house && request.type !== 'noInfo') {
    return {message: 'Raison: Hors zone', abandonmentReason: 'location'}
  } else if (request.type === 'noInfo') {
    const message = reasonMessages[request.situationInfo as TypePrecision]
    if (typeof message === 'function') {
      return {message: message(request.situationDetails), abandonmentReason: 'other'}
    } else if (message) {
      return {message, abandonmentReason: 'other'}
    }
  }

  return undefined
}

export function isNoFollowUpAbandonment(request: IErpRequest) {
  if (request.isAbandoned && (request.type === 'noInfo' || !request.house)) {
    return 'Demande non qualifiée'
  } else if (request.isAbandoned && (request.projects?.length ?? 0) > 0) {
    return 'Projet sans suite'
  } else return 'Demande abandonnée'
}
