import React, {createContext, useContext, useEffect, useState} from 'react'
import Front from '@frontapp/plugin-sdk'
import {WebViewContext} from '@frontapp/plugin-sdk/dist/webViewSdkTypes'

export const FrontContext = createContext<WebViewContext | null>(null)

export function useFrontContext() {
  return useContext(FrontContext)
}

export const FrontContextProvider = ({children}) => {
  const [context, setContext] = useState<WebViewContext | null>(null)

  useEffect(() => {
    const subscription = Front.contextUpdates.subscribe((frontContext: WebViewContext) => {
      setContext(frontContext)
    })
    return () => subscription.unsubscribe()
  }, [])

  return <FrontContext.Provider value={context}>{children}</FrontContext.Provider>
}
