import React from 'react'
import {ISelectOption} from 'types/app'
import './_checkbox.scss'

import {CheckboxType} from 'lib/enums'

interface ISelectProps {
  items: ISelectOption[]
  type?: CheckboxType
  value: string | Array<string> | undefined
  set?: (e) => void
  disabled?: boolean
  /** (default: false) If true the field will be displayed as a summary */
  isSummary?: boolean
}

const Checkbox = (props: ISelectProps) => {
  const {items, value, set, type = CheckboxType.CHECKBOX, isSummary} = props

  if (isSummary) {
    return <div className={'block'}>{items.find((item) => item.value === value)?.label}</div>
  } else {
    return (
      <div className={'select-row'}>
        {(items || []).map((item: ISelectOption) => {
          return (
            <div
              key={item.value}
              className={`select-list item${item.value === value ? ' checked' : ''}`}
              onClick={() => {
                if (set) set(item.value)
              }}
            >
              <div className='chip-container'>
                <span className={`chip ${type}`} />
              </div>
              <div className='text-container'>{item.label}</div>
            </div>
          )
        })}
      </div>
    )
  }
}

export default Checkbox
