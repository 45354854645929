import Alert from 'components/form/alert'
import {isNoFollowUpAbandonment} from 'helpers/request'
import {IAppContext, useAppContext} from 'providers/appProvider'
import RedoButton from '../redoBtn'
import Recap from './recap'

interface StepAbandonedProps {
  resetRequest: () => void
}

const StepAbandoned = ({resetRequest}: StepAbandonedProps) => {
  const {request, selects}: IAppContext = useAppContext()

  if (!selects) return <></>

  return (
    <>
      <div className='content'>
        <Alert color='danger' message={isNoFollowUpAbandonment(request)} icon='ArchiveFilled' />

        <Recap />
      </div>
      <div className={'bottom-fixed'}>
        <div className={'items-center'}>
          <RedoButton resetRequest={resetRequest} />
        </div>
      </div>
    </>
  )
}

export default StepAbandoned
