import {IconName, Input as FrontInput} from '@frontapp/ui-kit'
import {FocusEventHandler} from 'react'

interface IInputProps<T = string> {
  /** The id of the input field */
  id?: string
  /** The content of the input field */
  value?: T
  /** Type of input: number, text, email, url, password. Default to text. */
  type?: 'text' | 'email' | 'password' | 'url' | 'number' | 'phone'
  /** The name of the input field */
  name?: string
  /** Placeholder value */
  placeholder?: string
  /** Whether the input is disabled */
  isDisabled?: boolean
  /** The icon to render at the beginning of the input field */
  iconName?: IconName
  /** Whether the input is erred */
  isErred?: boolean
  /** Whether we should focus on the input when it is mounted */
  shouldFocus?: boolean
  /** Max width of the input. Default is 100%. */
  maxWidth?: number
  /** The handler for when the content of the input field changes */
  onChange?: (value: T) => void
  /** The handler for when the input field is unfocused */
  onBlur?: FocusEventHandler
  /** The handler for when the input field is focused */
  onFocus?: FocusEventHandler
  /** (default: false) if true, the input is displayed as a summary*/
  isSummary?: boolean
}

const Input = (props: IInputProps) => {
  const {isSummary, type, onChange, ...otherProps} = props

  if (isSummary) {
    return <div className={'block'}>{otherProps.value}</div>
  } else {
    const inputOnChange =
      type === 'phone'
        ? (value: string) => {
            if (onChange) onChange(value.replace(/[^0-9^+]/g, ''))
          }
        : onChange

    const inputType = type === 'phone' ? 'text' : type

    return <FrontInput type={inputType} onChange={inputOnChange} {...otherProps} />
  }
}

export default Input
