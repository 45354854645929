import Axios from 'axios'
import Cookies from 'lib/cookies'
import config from 'config'
import {TokenCookie} from 'lib/enums'

const axiosClients = () => {
  function createAxiosClient(token: string | null | undefined) {
    const env: string = config.erpEnv || 'local'

    return Axios.create({
      baseURL: config.apiUrl[env],
      timeout: 15000,
      headers: {
        ...(token ? {authorization: `Bearer ${token}`} : {}),
        appclient: 'crm'
      }
    })
  }

  const thirdParty = createAxiosClient(process.env.REACT_APP_FRONT_THIRD_PARTY_TOKEN)
  const anonymous = createAxiosClient(null)
  const teammateToken = new Cookies(config.cookiesPrefix.front).get(TokenCookie.KEY)
  const teammate = createAxiosClient(teammateToken)

  return {
    thirdParty,
    anonymous,
    teammate
  }
}

export default axiosClients
