import {
  formSchema as defaultFormSchema,
  formSchemaPartnership,
  formSchemaDisplayTypeOther,
  formSchemaUpcomingDeathNoHome
} from 'helpers/validator'
import {IErpRequest} from 'queries/types'
import {useMemo} from 'react'

export const useForm = (request: IErpRequest): boolean => {
  const {type, situationInfo, house, source} = request

  const formSchema = useMemo(() => {
    if (type === 'noInfo' && situationInfo !== 'cpo') {
      return formSchemaDisplayTypeOther
    } else if (house === 'other') {
      return formSchemaUpcomingDeathNoHome
    } else if (source === 'partnership') {
      return formSchemaPartnership
    } else {
      return defaultFormSchema
    }
  }, [request])

  const isFormValid = useMemo(() => {
    try {
      formSchema.validateSync(request)
    } catch (e) {
      console.warn('Form validation error:', e)
      return false
    }
    return formSchema.isValidSync(request, {
      stripUnknown: true
    })
  }, [request, formSchema])

  return isFormValid
}
