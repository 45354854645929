export const typeColor = {
  actions: '#f670c7',
  arguments: '#0ba5ec'
}

export const typeTitle = {
  actions: 'Actions 🎯',
  arguments: 'Arguments 💬'
}

export const noteTemplate =
  "Contexte (situation familiale, conditions particulières du décès ...) :\n\n\nDéfunt :\n- Date de naissance :\n- Lieu de repos :\n\nParcours d'obsèques :\n- Inhumation / crémation\n  > idée d’un lieu :\n- Cérémonie civile / religieuse\n  > idée d’un lieu :\n- TSC à prévoir : par la réception / par la maison / non\n- Souhaits particuliers :\n- Envoi d'une estimation : oui / non\n\nSi TSC :\n- Lieu de départ :\n- Lieu de d'arrivée :\n- Heure d'arrivée :\n- Prestataire :\n\nSi une information est manquante, préciser : “na“."
