import {useEffect, useState} from 'react'
import {SingleConversationContext} from '@frontapp/plugin-sdk'
import {Input} from '@frontapp/ui-kit'
import Select from 'components/form/select'
import {useForm} from 'hooks/use-form'
import {SelectKey} from 'lib/enums'
import {IAppContext, useAppContext} from 'providers/appProvider'
import {useFrontContext} from 'providers/frontContext'
import type {RequestField} from 'queries/types'
import {useUpsertRequest} from 'hooks/custom-queries'
import ContinueButton from './continueBtn'
import FormAlert from './formAlert'
import LogoutUser from './logoutUser'
import './_formView.scss'
import {getLinkedAgencies} from 'helpers/select'
import {RequestPartnership} from 'helpers/partnership'

interface IPartnershipViewProps {
  resetRequest: () => void
  requestPartnership: RequestPartnership
}

const PartnershipView = ({resetRequest, requestPartnership}: IPartnershipViewProps) => {
  const {request, setRequest, regionItems, selects, agencyItems}: IAppContext = useAppContext()
  if (!selects) return <></>
  const frontContext = useFrontContext() as SingleConversationContext

  const upsertRequest = useUpsertRequest(frontContext.conversation.id)

  const [linkedAgencyItems, setLinkedAgencyItems] = useState([])

  useEffect(() => {
    // we want to update list of agencies depending on the selected Région (house)
    const linked = getLinkedAgencies(request.house, agencyItems)
    setLinkedAgencyItems(linked)

    // QD1711627 - request.source can be changed from 'partnership' to 'call' on some cases
    // we never want to change .source or .recommandationOrigin
    setRequest(
      {
        ...request,
        source: 'partnership',
        recommandationOrigin: requestPartnership
      },
      frontContext.conversation.id
    )

    // QD1711627 - on load we want to check that agency is a string an not an object
    if (typeof request.agency === 'object') {
      setRequest(
        {
          ...request,
          agency: request.agency._id
        },
        frontContext.conversation.id
      )
    }
  }, [request.house, agencyItems])

  const setForm = (key: RequestField, subKey?: string) => (value) => {
    setRequest(
      {
        ...request,
        [key]: subKey ? {...request[key], [subKey]: value} : value
      },
      frontContext.conversation.id
    )
  }

  const isFormValid: boolean = useForm(request)

  return (
    <div id='src-form' className='container'>
      <div className='content'>
        {
          <>
            <LogoutUser />
            <FormAlert request={request} />
            <Select
              items={selects[SelectKey.CONTACT_REACHED]}
              value={request.contactReached}
              set={setForm('contactReached')}
              placeholder={'Contact recherché *'}
              position={'bottom-start'}
              disabled={true}
            />
            <Select
              items={selects[SelectKey.PROJECT_TYPE].filter(({value}) => value !== 'noInfo')}
              value={request.type}
              set={setForm('type')}
              placeholder={'Situation *'}
              position={'bottom-start'}
            />
            <Select
              items={regionItems}
              value={request.house?._id || request.house}
              set={setForm('house')}
              placeholder={'Région concernée *'}
              position={'bottom-start'}
            />
            {request.house !== 'other' && (
              <Select
                items={linkedAgencyItems}
                value={request.agency?._id || request.agency}
                set={setForm('agency')}
                placeholder={'Maison responsable'}
                position={'bottom-start'}
              />
            )}
            <h6>Informations du pouvoir</h6>
            <Input
              type={'text'}
              value={request.partnershipContact?.lastname || ''}
              placeholder={'Nom'}
              onChange={setForm('partnershipContact', 'lastname')}
            />
            <Input
              type={'text'}
              value={request.partnershipContact?.firstname || ''}
              placeholder={'Prénom'}
              onChange={setForm('partnershipContact', 'firstname')}
            />
            <div className='btn-bottom-right'>
              <ContinueButton
                isDisabled={!isFormValid}
                onClick={() => {
                  const updatedRequest = {
                    ...request,
                    isFirstStepManual: true
                  }
                  upsertRequest.upsert(updatedRequest).then((data) => {
                    setRequest(data)
                    resetRequest()
                  })
                }}
                loading={upsertRequest.isLoading}
                request={request}
              />
            </div>
          </>
        }
      </div>
    </div>
  )
}

export default PartnershipView
