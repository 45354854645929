import {SingleConversationContext} from '@frontapp/plugin-sdk'
import {Button, DatePicker, VisualSizesEnum} from '@frontapp/ui-kit'
import {useForm} from 'hooks/use-form'
import {useAppContext} from 'providers/appProvider'
import {useFrontContext} from 'providers/frontContext'
import type {ErpRequestStep, IErpRequestAttendant, RequestField} from 'queries/types'
import React, {useMemo, useState} from 'react'
import {ClipLoader} from 'react-spinners'
import {useUpsertRequest} from 'hooks/custom-queries'
import Select from 'components/form/select'
import './_successReasonModal.scss'
import Switch from 'components/form/switch'
import {formatDateTime, getTodayDate} from 'helpers/utils'
import apiClient from 'queries'
import AttendantPicker from 'components/attendantPicker'
import DateInput from 'components/form/date-input'

interface ISuccessModalProps {
  showModal: React.Dispatch<React.SetStateAction<boolean>>
}
const SuccessReasonModal = (props: ISuccessModalProps) => {
  const {showModal} = props
  const {request, setRequest, selects, linkedAgencyItems} = useAppContext()
  if (!selects) return <></>
  const frontContext = useFrontContext() as SingleConversationContext
  const {createCommentRequest} = apiClient()

  const [updatedRequest, setUpdatedRequest] = useState({
    ...request,
    step: 'sql' as ErpRequestStep,
    appointmentAtHome: false
  })

  const upsertRequest = useUpsertRequest(frontContext.conversation.id)

  const setForm = (key: RequestField, subKey?: string) => (value) => {
    setUpdatedRequest({
      ...updatedRequest,
      [key]: subKey ? {...updatedRequest[key], [subKey]: value} : value
    })
  }

  const attendant = useMemo(() => {
    if (updatedRequest.attendant) {
      if (typeof updatedRequest.attendant === 'string') return updatedRequest.attendant
      else return updatedRequest.attendant._id
    }

    if (request.attendant) {
      if (typeof request.attendant === 'string') return request.attendant
      else return request.attendant._id
    }

    return undefined
  }, [updatedRequest.attendant, request.attendant])

  const isFormValid: boolean = useForm(updatedRequest)

  const isWindowFocused = document.hasFocus()

  if (!isWindowFocused) {
    showModal(false)
  }

  const onSave = () => {
    upsertRequest.upsert(updatedRequest).then((data) => {
      setRequest(data)
      showModal(false)

      let appointmentAtHome = ''
      if (data.appointmentAtHome) {
        appointmentAtHome = '\nRDV à domicile ✅'
      }

      if (data.appointmentDate) {
        let body = `
        Statut: Devis à faire signer
        Le: ${getTodayDate()}
        Utilisateur: ${frontContext.teammate.name}
        Date de rdv: ${formatDateTime(data.appointmentDate)}${appointmentAtHome}
        `

        if (data.attendant) {
          body += `Accompagnant(e): ${(data.attendant as IErpRequestAttendant).name}`
        }

        createCommentRequest({
          conversationId: frontContext.conversation.id,
          comment: {
            body
          }
        })
      }
    })
  }

  return (
    <div className='modal'>
      <div id='modal' onClick={(e) => e.stopPropagation()} className='container'>
        <section>
          <h5>👏 RDV pris</h5>
          <Switch
            checked={updatedRequest.appointmentAtHome}
            set={setForm('appointmentAtHome')}
            label='RDV à domicile ?'
          />
          <Select
            items={linkedAgencyItems}
            value={updatedRequest.agency?._id || updatedRequest.agency || request.agency?._id}
            set={setForm('agency')}
            placeholder={`Maison responsable ${!updatedRequest.appointmentAtHome ? ' *' : ''}`}
          />
          <DateInput
            placeholder='Date du RDV *'
            value={updatedRequest.appointmentDate}
            allowTime
            onChange={(input) => {
              setForm('appointmentDate')(input)
            }}
          />

          <AttendantPicker
            house={updatedRequest.house?._id || updatedRequest.house}
            value={attendant}
            onChange={setForm('attendant')}
          />
        </section>
        <div className={'items-center'}>
          <Button
            type={'tertiary'}
            size={VisualSizesEnum.LARGE}
            className={'btn btn-cancel gap-sides'}
            onClick={() => {
              showModal(false)
            }}
          >
            Annuler
          </Button>
          <Button
            type={'primary'}
            size={VisualSizesEnum.LARGE}
            className={'btn gap-sides btn-validate-grey'}
            isDisabled={!isFormValid || upsertRequest.isLoading}
            onClick={onSave}
          >
            {upsertRequest.isLoading && (
              <ClipLoader loading size={3} aria-label='Loading' data-testid='loader' />
            )}
            <span>Valider</span>
          </Button>
        </div>
      </div>
    </div>
  )
}

export default SuccessReasonModal
