import CookiesTs, {CookiesOption} from 'cookies-ts'
import {SameSite} from 'lib/enums'

class Cookies {
  private prefix: string
  public cookies: CookiesTs
  private option: CookiesOption = {
    secure: true
  } as const

  constructor(prefix: string, option?: CookiesOption) {
    this.prefix = prefix
    this.cookies = new CookiesTs()
    this.option = {
      ...this.option,
      ...option
    }
  }

  get(key: string): string | null {
    return this.cookies.get(`${this.prefix}${key}`)
  }

  set(key: string, value: string, sameSite: SameSite = SameSite.NONE): Cookies {
    document.cookie = `${
      this.prefix
    }${key}=${value}; samesite=${sameSite}; secure; expires=${new Date(
      new Date().getTime() + 1000 * 60 * 60 * 24 * 365
    ).toString()} `
    return this
  }

  remove(key: string, sameSite: SameSite = SameSite.NONE): Cookies {
    this.set(key, '', sameSite)
    return this
  }
}

export default Cookies
