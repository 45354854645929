import {SingleConversationContext} from '@frontapp/plugin-sdk'
import {Button, Textarea, VisualSizesEnum} from '@frontapp/ui-kit'
import Checkbox from 'components/form/checkbox'
import {getTodayDate} from 'helpers/utils'
import {useForm} from 'hooks/use-form'
import {CheckboxType, SelectKey} from 'lib/enums'
import {useAppContext} from 'providers/appProvider'
import {useFrontContext} from 'providers/frontContext'
import type {ErpRequestStep, RequestField} from 'queries/types'
import React, {useState} from 'react'
import {ClipLoader} from 'react-spinners'
import {useUpsertRequest} from 'hooks/custom-queries'
import './_abandonmentModal.scss'
import apiClient from 'queries'

interface IAbandonmentModalProps {
  showModal: React.Dispatch<React.SetStateAction<boolean>>
}

const AbandonmentModal = (props: IAbandonmentModalProps) => {
  const {showModal} = props
  const {request, setRequest, selects} = useAppContext()
  const frontContext = useFrontContext() as SingleConversationContext
  const {createCommentRequest} = apiClient()

  if (!selects) return <></>

  const [updatedRequest, setUpdatedRequest] = useState({
    ...request,
    isAbandoned: true,
    step: 'abandoned' as ErpRequestStep
  })

  const upsertRequest = useUpsertRequest(frontContext.conversation.id)

  const setForm = (key: RequestField, subKey?: string) => (value) => {
    setUpdatedRequest({
      ...updatedRequest,
      [key]: subKey ? {...updatedRequest[key], [subKey]: value} : value
    })
  }

  const isFormValid: boolean = useForm(updatedRequest)

  const isWindowFocused = document.hasFocus()

  if (!isWindowFocused) {
    showModal(false)
  }

  const onSave = () => {
    upsertRequest.upsert(updatedRequest).then((data) => {
      setRequest(data)
      showModal(false)

      createCommentRequest({
        conversationId: frontContext.conversation.id,
        comment: {
          body: `
            Abandon manuel le: ${getTodayDate()}
            Utilisateur: ${frontContext.teammate.name}
            Raison: ${updatedRequest.abandonmentReason}${
            updatedRequest.customAbandonmentReason
              ? ',\nPrécisions: ' + updatedRequest.customAbandonmentReason
              : ''
          }`
        }
      })
    })
  }

  return (
    <div className='modal spaceTop'>
      <div id='modal' onClick={(e) => e.stopPropagation()} className='container'>
        <section>
          <h5>😢 Pas de suite</h5>
          <p>Selon vous, pourquoi la famille n&apos;a pas donné suite ? *</p>
          <Checkbox
            items={selects[SelectKey.ABANDONMENT_REASON]}
            type={CheckboxType.RADIO}
            value={updatedRequest.abandonmentReason || ''}
            set={setForm('abandonmentReason')}
          />
          <Textarea
            value={updatedRequest.customAbandonmentReason || ''}
            placeholder={'Précisions' + (updatedRequest.abandonmentReason === 'other' ? ' *' : '')}
            onChange={setForm('customAbandonmentReason')}
            rows={2}
          />
        </section>
        <div className={'items-center'}>
          <Button
            type={'tertiary'}
            size={VisualSizesEnum.LARGE}
            className={'btn btn-cancel gap-sides'}
            onClick={() => {
              showModal(false)
            }}
          >
            Annuler
          </Button>
          <Button
            type={'primary'}
            size={VisualSizesEnum.LARGE}
            className={'btn gap-sides btn-validate-grey'}
            isDisabled={!isFormValid || upsertRequest.isLoading}
            onClick={onSave}
          >
            {upsertRequest.isLoading && (
              <ClipLoader loading size={3} aria-label='Loading' data-testid='loader' />
            )}
            <span>Valider</span>
          </Button>
        </div>
      </div>
    </div>
  )
}

export default AbandonmentModal
