import {ApplicationTag} from '@frontapp/plugin-sdk'

const ofpfTagId = 'tag_45clqm'
const mpfTagId = 'tag_45cle6'

export type RequestPartnership = 'ofpf' | 'mpf'

export function getPartnership(conversation): RequestPartnership | null {
  if (
    conversation.tags.find((tag: ApplicationTag) => {
      return tag.id === ofpfTagId
    })
  ) {
    return 'ofpf'
  }

  if (
    conversation.tags.find((tag: ApplicationTag) => {
      return tag.id === mpfTagId
    })
  ) {
    return 'mpf'
  }

  return null
}
