import {SingleConversationContext} from '@frontapp/plugin-sdk'
import Alert from 'components/form/alert'
import {useUpsertRequest} from 'hooks/custom-queries'
import {IAppContext, useAppContext} from 'providers/appProvider'
import {useFrontContext} from 'providers/frontContext'
import Recap from './recap'
import {Button, VisualSizesEnum} from '@frontapp/ui-kit'

interface StepSqlProps {
  setAttendantModal: (value: boolean) => void
}

const StepSql = ({setAttendantModal}: StepSqlProps) => {
  const {request}: IAppContext = useAppContext()

  const frontContext = useFrontContext() as SingleConversationContext

  const upsertRequest = useUpsertRequest(frontContext.conversation.id)

  return (
    <>
      <div className='content'>
        <Alert color='success' inversedColors message='Devis à faire signer' icon='StarFilled' />

        <Recap />
      </div>
      <div className={'bottom-fixed'}>
        {upsertRequest.error && (
          <>
            <div className='alert-danger-bottom'>
              <Alert
                color='danger'
                inversedColors
                message={`Erreur lors de l'enregistrement : ${upsertRequest.error}`}
              />
            </div>
          </>
        )}
        {!request.attendant && (
          <div className={'items-center'}>
            <Button
              type={'primary'}
              size={VisualSizesEnum.LARGE}
              className={`btn`}
              onClick={() => {
                setAttendantModal(true)
              }}
            >
              Choisir un accompagnant
            </Button>
          </div>
        )}
      </div>
    </>
  )
}

export default StepSql
