import Alert from 'components/form/alert'
import {IAppContext, useAppContext} from 'providers/appProvider'
import Recap from './recap'

const StepOrder = () => {
  const {selects}: IAppContext = useAppContext()

  if (!selects) return <></>

  return (
    <>
      <div className='content'>
        <Alert color='success' inversedColors message='Devis signé' icon='CheckmarkCircle' />

        <Recap />

        <div className='order-container'>
          <h1 className='order-container-emoji'>✨</h1>
          <div className='order-container-txt'>Une famille de plus accompagnée par inmemori</div>
        </div>
      </div>
    </>
  )
}

export default StepOrder
