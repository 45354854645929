import {ApplicationConversation} from '@frontapp/plugin-sdk'

interface IHouseDataResult {
  house?: ''
  source: string
  wording?: string
  numberCalled?: string
  numberCalling?: string
}

export default class RingOver {
  private conversation: ApplicationConversation
  private static Outbound = 'outbound'
  private static DefaultWording = 'organic'
  private static phoneRegex = /((\+)\d{2}|0)[1-9](\d{2}){4}/g

  constructor(conversation: ApplicationConversation) {
    this.conversation = conversation
  }

  get isOutbound(): boolean {
    return !!this.conversation.subject?.toLocaleLowerCase().startsWith(RingOver.Outbound)
  }

  get numbers(): string[] {
    const numbers: string[] = [
      ...new Set(this.conversation.subject?.matchAll(RingOver.phoneRegex))
    ].map(([it]) => it)
    return numbers
  }

  get numberCalled(): string {
    const index = this.isOutbound ? 0 : 1
    return this.numbers.length > 1 ? this.numbers[index] : this.numbers[0]
  }

  get numberCalling(): string {
    const index = this.isOutbound ? 1 : 0
    return this.numbers.length > 1 ? this.numbers[index] : this.numbers[0]
  }

  getHouseData(houses: any[]): IHouseDataResult {
    const result: IHouseDataResult = {
      house: '',
      source: 'call',
      wording: RingOver.DefaultWording
    }

    const matchHouses = houses.filter(({phones}) => {
      const phone = phones?.find(({phone}) => phone === this.numberCalled)
      if (phone) {
        result.wording = phone.label
      }
      return !!phone
    })

    if (matchHouses.length === 1) result.house = matchHouses[0]._id

    return {
      ...result,
      numberCalled: this.numberCalled,
      numberCalling: this.numberCalling
    }
  }
}
