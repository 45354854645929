import React from 'react'
import ReactDOM from 'react-dom/client'
import {QueryClient, QueryClientProvider} from 'react-query'
import App from 'App'
import {FrontContextProvider} from 'providers/frontContext'
import {AppContextProvider} from 'providers/appProvider'
import reportWebVitals from 'reportWebVitals'
import './lib/datadog/rum'
import {ErrorBoundary} from './lib/datadog'

import './index.scss'

const rootElement: HTMLElement | null = document.getElementById('root')
if (!rootElement) throw new Error('Failed to find the root element')
const root: ReactDOM.Root = ReactDOM.createRoot(rootElement)

root.render(
  <ErrorBoundary fallback={(error) => <div>Problème au chargement: {error.message}</div>}>
    <React.StrictMode>
      <QueryClientProvider client={new QueryClient()}>
        <FrontContextProvider>
          <AppContextProvider>
            <App />
          </AppContextProvider>
        </FrontContextProvider>
      </QueryClientProvider>
    </React.StrictMode>
  </ErrorBoundary>
)

reportWebVitals()
