import {SingleConversationContext} from '@frontapp/plugin-sdk'
import {Button, VisualSizesEnum} from '@frontapp/ui-kit'
import {useAppContext} from 'providers/appProvider'
import {useFrontContext} from 'providers/frontContext'
import type {RequestField} from 'queries/types'
import React, {useMemo, useState} from 'react'
import {ClipLoader} from 'react-spinners'
import {useUpsertRequest} from 'hooks/custom-queries'
import AttendantPicker from 'components/attendantPicker'

interface IAttendantModalProps {
  showModal: React.Dispatch<React.SetStateAction<boolean>>
}
const AttendantReasonModal = (props: IAttendantModalProps) => {
  const {showModal} = props
  const {request, setRequest} = useAppContext()
  const frontContext = useFrontContext() as SingleConversationContext

  const [updatedRequest, setUpdatedRequest] = useState({
    ...request
  })

  const upsertRequest = useUpsertRequest(frontContext.conversation.id)

  const setForm = (key: RequestField, subKey?: string) => (value) => {
    setUpdatedRequest({
      ...updatedRequest,
      [key]: subKey ? {...updatedRequest[key], [subKey]: value} : value
    })
  }

  const attendant = useMemo(() => {
    if (updatedRequest.attendant) {
      if (typeof updatedRequest.attendant === 'string') return updatedRequest.attendant
      else return updatedRequest.attendant?._id
    }

    if (request.attendant) {
      if (typeof request.attendant === 'string') return request.attendant
      else return request.attendant?._id
    }

    return undefined
  }, [updatedRequest.attendant, request.attendant])

  const isWindowFocused = document.hasFocus()

  if (!isWindowFocused) {
    showModal(false)
  }

  const onSave = () => {
    upsertRequest.upsert(updatedRequest).then((data) => {
      setRequest(data)
      showModal(false)
    })
  }

  return (
    <div className='modal'>
      <div id='modal' onClick={(e) => e.stopPropagation()} className='container'>
        <section>
          <h5>Accompagnant en charge du RDV</h5>
          <AttendantPicker
            house={updatedRequest.house?._id || updatedRequest.house}
            value={attendant}
            onChange={setForm('attendant')}
          />
        </section>
        <div className={'items-center'}>
          <Button
            type={'tertiary'}
            size={VisualSizesEnum.LARGE}
            className={'btn btn-cancel gap-sides'}
            onClick={() => {
              showModal(false)
            }}
          >
            Annuler
          </Button>
          <Button
            type={'primary'}
            size={VisualSizesEnum.LARGE}
            className={'btn gap-sides btn-validate-grey'}
            isDisabled={!attendant || upsertRequest.isLoading}
            onClick={onSave}
          >
            {upsertRequest.isLoading && (
              <ClipLoader loading size={3} aria-label='Loading' data-testid='loader' />
            )}
            <span>Valider</span>
          </Button>
        </div>
      </div>
    </div>
  )
}

export default AttendantReasonModal
