import {useRef} from 'react'
import Datetime from 'react-datetime'
import moment, {Moment} from 'moment-timezone'

import './_date-input.scss'

interface IDateInputProps {
  placeholder?: string
  value?: Date
  allowTime?: boolean
  onChange: (value: string | Moment) => void
}

const hoursList = Array.from({length: 24}, (_, i) => {
  return {label: i.toString().padStart(2, '0'), value: i.toString()}
})

const minutesStepList = [
  {label: '00', value: '00'},
  {label: '15', value: '15'},
  {label: '30', value: '30'},
  {label: '45', value: '45'}
]

const DateInput = ({placeholder, value, allowTime = false, onChange}: IDateInputProps) => {
  const ref = useRef<any>(null)

  // eslint-disable-next-line @typescript-eslint/ban-types
  const renderView = (mode: string, renderCalendar: Function) => {
    if (mode !== 'time') return renderCalendar()

    const currentHour = value && parseInt(moment(value).format('HH')).toString()
    const currentMinutes = value && parseInt(moment(value).format('mm')).toString()
    const currentDate = moment(value).format('DD/MM/YYYY')

    return (
      <div className='time-view-wrapper'>
        <div onClick={() => ref?.current?.navigate('days')} className='time-view-date'>
          {currentDate}
        </div>
        <div className='time-view-input'>
          <select
            value={currentHour}
            onChange={(e) =>
              onChange(
                moment(value).set({hours: parseInt(e.target.value), ...(!value && {minutes: 0})})
              )
            }
            className='time-view-select'
          >
            {hoursList.map((hour) => (
              <option key={hour.value} value={hour.value}>
                {hour.label}
              </option>
            ))}
          </select>
          <div>:</div>
          <select
            value={currentMinutes}
            onChange={(e) => onChange(moment(value).set({minutes: parseInt(e.target.value)}))}
            className='time-view-select'
          >
            {minutesStepList.map((hour) => (
              <option key={hour.value} value={hour.value}>
                {hour.label}
              </option>
            ))}
          </select>
        </div>
      </div>
    )
  }

  const renderInput = (props, openCalendar) => {
    return (
      <div className='date-input-group'>
        <div className='date-input-container'>
          <div className='date-input-icon' onClick={openCalendar}>
            <svg
              width='16'
              height='16'
              viewBox='0 0 16 16'
              fill='currentColor'
              xmlns='http://www.w3.org/2000/svg'
              name='Calendar'
              color='#bbbdbf'
              preserveAspectRatio='none'
            >
              <path
                fillRule='evenodd'
                clipRule='evenodd'
                d='M2.02315 1.26713C2.42514 1.05215 2.80949 1 3.56389 1H12.4361C13.1905 1 13.5749 1.05215 13.9768 1.26713C14.3028 1.44144 14.5586 1.69723 14.7329 2.02315C14.9479 2.42514 15 2.80949 15 3.56389V4.5V5.5V12.4361C15 13.1905 14.9479 13.5749 14.7329 13.9768C14.5586 14.3028 14.3028 14.5586 13.9768 14.7329C13.5749 14.9479 13.1905 15 12.4361 15H3.56389C2.80949 15 2.42514 14.9479 2.02315 14.7329C1.69723 14.5586 1.44144 14.3028 1.26713 13.9768C1.05215 13.5749 1 13.1905 1 12.4361V5.5V4.5V3.56389C1 2.80949 1.05215 2.42514 1.26713 2.02315C1.44144 1.69723 1.69723 1.44144 2.02315 1.26713ZM14 3.56389V4.5H2V3.56389C2 2.84361 2.05581 2.6689 2.14895 2.49475C2.23005 2.3431 2.3431 2.23005 2.49475 2.14895C2.6689 2.05581 2.84361 2 3.56389 2H12.4361C13.1564 2 13.3311 2.05581 13.5052 2.14895C13.6569 2.23005 13.7699 2.3431 13.8511 2.49475C13.9442 2.6689 14 2.84361 14 3.56389ZM2 12.4361V5.5H14V12.4361C14 13.1564 13.9442 13.3311 13.8511 13.5052C13.7699 13.6569 13.6569 13.7699 13.5052 13.8511C13.3311 13.9442 13.1564 14 12.4361 14H3.56389C2.84361 14 2.6689 13.9442 2.49475 13.8511C2.3431 13.7699 2.23005 13.6569 2.14895 13.5052C2.05581 13.3311 2 13.1564 2 12.4361ZM3.75 11.25C3.75 11.6642 4.08579 12 4.5 12C4.91421 12 5.25 11.6642 5.25 11.25C5.25 10.8358 4.91421 10.5 4.5 10.5C4.08579 10.5 3.75 10.8358 3.75 11.25ZM8 12C7.58579 12 7.25 11.6642 7.25 11.25C7.25 10.8358 7.58579 10.5 8 10.5C8.41421 10.5 8.75 10.8358 8.75 11.25C8.75 11.6642 8.41421 12 8 12ZM10.75 11.25C10.75 11.6642 11.0858 12 11.5 12C11.9142 12 12.25 11.6642 12.25 11.25C12.25 10.8358 11.9142 10.5 11.5 10.5C11.0858 10.5 10.75 10.8358 10.75 11.25ZM4.5 9C4.08579 9 3.75 8.66421 3.75 8.25C3.75 7.83579 4.08579 7.5 4.5 7.5C4.91421 7.5 5.25 7.83579 5.25 8.25C5.25 8.66421 4.91421 9 4.5 9ZM7.25 8.25C7.25 8.66421 7.58579 9 8 9C8.41421 9 8.75 8.66421 8.75 8.25C8.75 7.83579 8.41421 7.5 8 7.5C7.58579 7.5 7.25 7.83579 7.25 8.25Z'
                fill='currentColor'
              ></path>
            </svg>
          </div>
          <div {...props} className={`${props.className} ${!props.value ? 'placeholder' : ''}`}>
            {props.value || props.placeholder}
          </div>
        </div>
        <div className='date-input-arrow-block' onClick={openCalendar}>
          <svg
            width='16'
            height='16'
            viewBox='0 0 16 16'
            fill='currentColor'
            xmlns='http://www.w3.org/2000/svg'
            name='ChevronDown'
            color='#77787a'
            preserveAspectRatio='none'
          >
            <path
              fillRule='evenodd'
              clipRule='evenodd'
              d='M3.41634 6.80244L7.41634 10.9251C7.70478 11.2223 8.17243 11.2223 8.46087 10.9251L12.4609 6.80244C12.7493 6.50516 12.7493 6.02317 12.4609 5.72589C12.1724 5.42861 11.7048 5.42861 11.4163 5.72589L7.93861 9.31024L4.46087 5.72589C4.17243 5.42861 3.70478 5.42861 3.41634 5.72589C3.1279 6.02317 3.1279 6.50516 3.41634 6.80244Z'
              fill='currentColor'
            ></path>
          </svg>
        </div>
      </div>
    )
  }

  return (
    <Datetime
      ref={ref}
      timeFormat={allowTime}
      value={value}
      renderView={renderView}
      renderInput={renderInput}
      inputProps={{placeholder: placeholder, className: 'date-input'}}
      onChange={onChange}
    />
  )
}

export default DateInput
