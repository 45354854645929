import * as Front from '@frontapp/ui-kit'
import {Placement} from '@popperjs/core'
import {ISelectOption} from 'types/app'
import React from 'react'

interface ISelectProps {
  items: ISelectOption[]
  value?: string
  set: (e) => void
  placeholder?: string
  position?: Placement
  disabled?: boolean
  errored?: boolean
  /** (default: false) If true the field will be displayed as a summary */
  isSummary?: boolean
  className?: string
}

const Select = (props: ISelectProps) => {
  const {
    items,
    value,
    set,
    placeholder,
    position,
    disabled,
    errored,
    isSummary: summary = false,
    className
  } = props

  const selectedValue = items.find((item) => item.value === value)?.label

  if (summary) {
    return <div className={`block ${className}`}>{selectedValue}</div>
  } else {
    return (
      items && (
        <div className={`form-select ${errored ? 'error' : ''} ${className}`}>
          <Front.Select
            selectedValues={selectedValue}
            placeholder={placeholder}
            placement={position}
            isDisabled={disabled}
          >
            {items.map((item: ISelectOption) => (
              <Front.SelectItem
                key={item.value}
                onClick={() => set(item.value)}
                isSelected={item.value === value}
              >
                {item.label}
              </Front.SelectItem>
            ))}
          </Front.Select>
        </div>
      )
    )
  }
}

export default Select
