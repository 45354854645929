import {AxiosResponse} from 'axios'
import config from 'config'
import axiosClients from 'lib/api'
import {SelectKey} from 'lib/enums'
import {
  ApiResponse,
  ApplicationCommentConversation,
  IAbandonmentPayload,
  IErpNote,
  IErpRequest,
  IErpRequestAttendant,
  IErpRequestContact,
  ILoginPayload,
  ILoginResponse,
  ILookup,
  ILookupResponse,
  ISelectResponse,
  IUpdateProjectPayload,
  IUserProfile
} from 'queries/types'

const apiClient = () => {
  const clients = axiosClients()

  const login = (body: ILoginPayload): ApiResponse<ILoginResponse> =>
    clients.anonymous.post('/auth/login', body)

  const getHouses = (): ApiResponse => clients.thirdParty.get('/houses')

  const getAgencies = (): ApiResponse => clients.thirdParty.get('/agencies')

  const getRequests = (
    lookup?: ILookup
  ): Promise<AxiosResponse<ILookupResponse<IErpRequest | undefined>>> => {
    return clients.teammate.post('/requests/lookup', lookup)
  }

  const upsertRequest = (request: IErpRequest): ApiResponse<{request: IErpRequest}> => {
    return clients.teammate.post('/requests/upsert', request, {
      params: {
        action: config.actions.upsert
      }
    })
  }

  const upsertNote = (requestId: string, note: string): ApiResponse<{note: IErpNote}> => {
    return clients.teammate.post(`/requests/${requestId}/upsertNote`, {note})
  }

  const abandonRequest = ({requestId, abandonmentReason}: IAbandonmentPayload): ApiResponse =>
    clients.teammate.post(
      `/requests/${requestId}/abandon`,
      {abandonmentReason},
      {
        params: {
          action: config.actions.abandon
        }
      }
    )

  const fetchAttendants = (
    houseId: string
  ): Promise<AxiosResponse<ILookupResponse<IErpRequestAttendant>>> =>
    clients.teammate.post('/profiles/lookup', {
      q: {
        'relations.teams.name': 'Accompagnant',
        'relations.houses._id': houseId
      },
      matchLookup: [
        {$match: {role: {$in: ['admin', 'superAdmin']}}},
        {
          $lookup: {
            from: 'profileRelations',
            localField: '_id',
            foreignField: 'profile',
            as: 'relations',
            pipeline: [
              {$match: {deleted: {$ne: true}}},
              {
                $facet: {
                  teams: [
                    {$match: {domain: 'team'}},
                    {
                      $lookup: {
                        from: 'teams',
                        localField: 'link',
                        foreignField: '_id',
                        as: 'teamDetails'
                      }
                    },
                    {$unwind: '$teamDetails'},
                    {$replaceRoot: {newRoot: '$teamDetails'}}
                  ],
                  houses: [
                    {$match: {domain: 'house'}},
                    {
                      $lookup: {
                        from: 'houses',
                        localField: 'link',
                        foreignField: '_id',
                        as: 'houseDetails'
                      }
                    },
                    {$unwind: '$houseDetails'},
                    {$replaceRoot: {newRoot: '$houseDetails'}}
                  ]
                }
              },
              {$project: {teams: 1, houses: 1}}
            ]
          }
        }
      ],
      sort: {firstname: 1, lastname: 1}
    } as ILookup)

  const createCommentRequest = ({conversationId, comment}: ApplicationCommentConversation) =>
    clients.teammate.post(`/front/conversations/${conversationId}/comments`, comment)

  const getContactById = (id: string): ApiResponse<IErpRequestContact> =>
    clients.teammate.get(`/front/contacts/${id}`)

  const updateProject = (project: IUpdateProjectPayload): ApiResponse =>
    clients.teammate.put(`/projects/${project._id}`, project)

  const getSelects = (): Promise<AxiosResponse<ILookupResponse<ISelectResponse>>> =>
    clients.thirdParty.post('/selects/lookup', {
      q: {
        key: {
          $in: Object.values(SelectKey)
        }
      },
      hydrate: true
    } as ILookup)

  const getUserProfile = (): Promise<IUserProfile> =>
    clients.teammate.get('/profiles/self').then(({data}) => {
      return data.data
    })

  return {
    login,
    getHouses,
    getAgencies,
    getRequests,
    upsertRequest,
    upsertNote,
    abandonRequest,
    createCommentRequest,
    getContactById,
    updateProject,
    getSelects,
    getUserProfile,
    fetchAttendants
  }
}

export default apiClient
