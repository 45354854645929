import './_alert.scss'
import {Icon, IconName} from '@frontapp/ui-kit'

interface IAlertProps {
  message?: string
  color?: 'success' | 'danger' | 'warning' | 'info'
  inversedColors?: boolean
  icon?: IconName
}

const Alert = (props: IAlertProps) => {
  const {message = '', icon, color = 'success', inversedColors} = props
  return (
    <div className={`alert alert-${color} ${inversedColors ? 'alert-inversed-colors' : ''}`}>
      {icon && <Icon name={icon} shouldDisableColor />}
      &nbsp;<span>{message}</span>
    </div>
  )
}

export default Alert
