import {SingleConversationContext} from '@frontapp/plugin-sdk'
import Alert from 'components/form/alert'
import Loader from 'components/loader'
import {getPartnership} from 'helpers/partnership'
import RingOver from 'helpers/ringover'
import {IAppContext, useAppContext} from 'providers/appProvider'
import {ErpRequestStep, IErpRequest} from 'queries/types'
import {useEffect, useMemo, useState} from 'react'
import FormView from './formView'
import PartnershipView from './partnershipView'
import {getDraftRequest} from 'helpers/draftRequestPersistency'
import {useFrontContext} from 'providers/frontContext'
import apiClient from 'queries'
import LogoutUser from './logoutUser'

enum ConversationDataStatus {
  LOADING = 'loading',
  SUCCESS = 'success'
}

const SingleConversation = () => {
  const context = useFrontContext() as SingleConversationContext
  const {setRequest, houses, isLoadingData, request}: IAppContext = useAppContext()
  const {getContactById, getRequests} = apiClient()
  //dataStatus : loading/success or an error string to be displayed to the user
  const [dataStatus, setDataStatus] = useState<ConversationDataStatus | string>(
    ConversationDataStatus.LOADING
  )
  const [contact, setContact] = useState<any>()

  const defaultEmptyRequest = {
    situationInfo: undefined,
    type: undefined,
    house: undefined,
    agency: undefined,
    isAbandoned: false,
    abandonmentReason: '',
    pending: true,
    step: 'new' as ErpRequestStep
  }

  const requestPartnership = useMemo(
    () => getPartnership(context.conversation),
    [context.conversation]
  )
  const frontContactId = context.conversation.recipient?.contact?.id

  useEffect(() => {
    async function createRequest() {
      setDataStatus(ConversationDataStatus.LOADING)
      if (!frontContactId) {
        setDataStatus("Aucun contact n'a été trouvé pour cette conversation")
        return
      }

      if (!houses) {
        console.log('No houses')
        setDataStatus("Les maisons n'ont pas pu être chargées")
        return
      }

      try {
        const contactResult = await getContactById(frontContactId).then((res) => res.data.data)

        const contactData = {
          frontContactId: contactResult.id,
          contact: contactResult,
          conversationId: context.conversation.id,
          ...(new RingOver(context.conversation).getHouseData(houses) as IErpRequest)
        }
        setContact(contactData)

        if (requestPartnership) {
          setRequest({
            ...contactData,
            recommandationOrigin: requestPartnership,
            requestType: 'quote',
            source: 'partnership',
            contactReached: 'inmemori',
            step: 'new' as ErpRequestStep
          })
          setDataStatus(ConversationDataStatus.SUCCESS)
          return
        }

        const requestResult = await getRequests({
          q: {
            frontContactId
          },
          limit: 1,
          hydrate: true
        }).then((res) => res.data.data.data[0])

        // Load a draft request stored in the local storage
        const draftRequest = getDraftRequest(context.conversation.id)
        if (
          draftRequest &&
          ((requestResult && draftRequest.step === requestResult.step) || !requestResult)
        ) {
          setRequest(draftRequest)
          setDataStatus(ConversationDataStatus.SUCCESS)
        }

        if (requestResult) {
          // Request found in DB, set the local request state
          setRequest({
            ...contactData,
            ...requestResult,
            ...(requestResult?.house ? {house: requestResult?.house?._id} : {}),
            ...(requestResult?.notes?.filter((note) => note.type === 'reception')?.length && {
              note: requestResult?.notes.filter((note) => note.type === 'reception')?.[0].data
            })
          })
        } else {
          // No request found in DB, creating an empty one with the associated contact data
          setRequest({
            ...defaultEmptyRequest,
            ...contactData
          })
        }

        setDataStatus(ConversationDataStatus.SUCCESS)
      } catch (e) {
        //Something went wrong with one of the queries
        setDataStatus(e.message)
      }
    }

    createRequest()
  }, [context.conversation.id, isLoadingData])

  const resetRequest = () => {
    setRequest(
      {
        ...contact,
        ...defaultEmptyRequest,
        ...(requestPartnership
          ? {
              recommandationOrigin: requestPartnership,
              requestType: 'quote', // default value but can be changed (not the two others)
              source: 'partnership',
              contactReached: 'inmemori'
            }
          : {})
      },
      context.conversation.id
    )
  }

  if (dataStatus === ConversationDataStatus.LOADING) {
    return <Loader />
  } else if (dataStatus === ConversationDataStatus.SUCCESS) {
    if (requestPartnership) {
      return <PartnershipView resetRequest={resetRequest} requestPartnership={requestPartnership} />
    } else {
      return <FormView resetRequest={resetRequest} />
    }
  } else {
    return (
      <>
        <Alert
          color='danger'
          inversedColors
          message={`Erreur : ${dataStatus}. Veuillez contacter le support.`}
        ></Alert>
        <LogoutUser />
      </>
    )
  }
}

export default SingleConversation
