import moment from 'moment-timezone'

export const formatDate = (date: Date | string): string => {
  return moment(date).format('DD/MM/YYYY')
}

export const formatDateTime = (date: Date | string): string => {
  return moment(date).format('DD/MM/YYYY à HH:mm')
}

export const getTodayDate = (): string => {
  const today = new Date()
  return formatDateTime(today)
}
