import {IErpRequest} from 'queries/types'

export const getDraftRequest = (conversationId: string): IErpRequest | undefined => {
  const draftRequest = sessionStorage.getItem(conversationId)
  return draftRequest ? JSON.parse(draftRequest) : undefined
}

export const setDraftRequest = (conversationId: string, request: IErpRequest) => {
  sessionStorage.setItem(conversationId, JSON.stringify(request))
}

export const removeDraftRequest = (conversationId: string) => {
  sessionStorage.removeItem(conversationId)
}
