import Alert from 'components/form/alert'
import {useFrontContext} from 'providers/frontContext'
import {useUpsertRequest} from 'hooks/custom-queries'
import {IAppContext, useAppContext} from 'providers/appProvider'
import {SingleConversationContext} from '@frontapp/plugin-sdk'
import {Button, VisualSizesEnum} from '@frontapp/ui-kit'
import ContinueButton from '../continueBtn'
import {useForm} from 'hooks/use-form'
import Recap from './recap'

interface StepMqlProps {
  setAbandonModal: (value: boolean) => void
  setSuccessReasonModal: (value: boolean) => void
}

const StepMql = ({setAbandonModal, setSuccessReasonModal}: StepMqlProps) => {
  const {request, hasProject}: IAppContext = useAppContext()
  const frontContext = useFrontContext() as SingleConversationContext
  const upsertRequest = useUpsertRequest(frontContext.conversation.id)

  const isFormValid: boolean = useForm(request)

  const onSave = () => {
    setSuccessReasonModal(true)
  }

  return (
    <>
      <div className='content'>
        <Alert color='success' message='Rendez-vous à prendre' icon='StarFilled' />

        <Recap noteEditable />
      </div>
      <div className={'bottom-fixed'}>
        {upsertRequest.error && (
          <>
            <div className='alert-danger-bottom'>
              <Alert
                color='danger'
                inversedColors
                message={`Erreur lors de l'enregistrement : ${upsertRequest.error}`}
              />
            </div>
          </>
        )}
        <div className={'items-center'}>
          <Button
            type={'tertiary'}
            isDisabled={!isFormValid}
            size={VisualSizesEnum.LARGE}
            className={`btn btn-cancel gap-sides ${!isFormValid ? 'btn-disabled' : ''}`}
            onClick={() => setAbandonModal(true)}
          >
            Pas de suite
          </Button>

          <ContinueButton
            isDisabled={!hasProject ? false : !isFormValid || upsertRequest.isLoading}
            onClick={onSave}
            loading={upsertRequest.isLoading}
            request={request}
          />
        </div>
      </div>
    </>
  )
}

export default StepMql
