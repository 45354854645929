import './_app.scss'
import {useFrontContext} from 'providers/frontContext'
import SingleConversation from 'views/singleConversation'
import React from 'react'
import Authentication from 'views/authentication'
import Loader from 'components/loader'

import {library, config as faConfig} from '@fortawesome/fontawesome-svg-core'
import {far} from '@fortawesome/free-regular-svg-icons'
import {fas} from '@fortawesome/free-solid-svg-icons'
import {useAppContext} from 'providers/appProvider'
import moment from 'moment-timezone'
import 'moment/locale/fr'

import 'react-datetime/css/react-datetime.css'

faConfig.autoA11y = true
library.add(far, fas)

enum ContextView {
  AUTH = 'authentication',
  SC = 'singleConversation',
  MC = 'multiConversations'
}

moment.locale('fr')
moment.tz.setDefault('Europe/Paris')

const App = () => {
  const frontContext = useFrontContext()
  const {isLoadingData, isLoggedIn} = useAppContext()

  if (!frontContext || isLoadingData)
    return (
      <div id='app'>
        <Loader />
      </div>
    )

  const renderContextView = (type: string) => {
    switch (type) {
      case ContextView.SC:
        return <SingleConversation />
      default:
        return <Loader />
    }
  }

  return (
    <div id='app'>{!isLoggedIn ? <Authentication /> : renderContextView(frontContext.type)}</div>
  )
}

export default App
