import {useEffect, useState} from 'react'
import Select from 'components/form/select'
import apiClient from 'queries'
import {ISelectOption} from 'types/app'

interface IAttendantPickerProps {
  house: string | any
  value: string | undefined
  onChange: (value: string) => void
}

const AttendantPicker = (props: IAttendantPickerProps) => {
  const [attendants, setAttendants] = useState<Array<ISelectOption>>([])

  useEffect(() => {
    // fetch attendants
    apiClient()
      .fetchAttendants(props.house._id || props.house)
      .then((data) => {
        setAttendants(
          data?.data?.data?.data?.map((profile) => ({value: profile._id, label: profile.name})) ||
            []
        )
      })
  }, [props.house])

  if (!attendants || !attendants.length) return null

  return (
    <Select
      items={attendants}
      value={props.value}
      set={props.onChange}
      placeholder={'Accompagnant'}
    />
  )
}

export default AttendantPicker
