export enum TokenCookie {
  DOMAIN = 'app.frontapp.com',
  KEY = 'erp_token'
}

export enum SameSite {
  NONE = 'none',
  STRICT = 'strict',
  LAX = 'lax'
}

// Single Conversation Step enumeration
export enum SCStep {
  FORM,
  SUMMARY,
  REDO
}

export enum CheckboxType {
  CHECKBOX = 'check',
  RADIO = 'radio'
}

export enum SelectKey {
  ABANDONMENT_REASON = 'front.abandonmentReason',
  SUCCESS_REASON = 'front.successReason',
  CONTACT_REACHED = 'contactReached', // TODO: delete from OFPF?
  PROJECT_TYPE = 'projectType',
  AGE_RANGE = 'ageRange',
  OTHER_SITUATION = 'otherSituation',
  PROJECT_TYPE_NO_INFO = 'projectTypeNoInfo', // todo: delete
  REQUEST_TYPE = 'requestType',
  CONCERN_FORM_SECTIONS = 'concernFormSections',
  CIVILITY = 'civility',
  RELATIONSHIPS = 'frontRelationships'
}
