import Alert from 'components/form/alert'
import {IAppContext, useAppContext} from 'providers/appProvider'
import Recap from './recap'

const StepCpo = () => {
  const {selects}: IAppContext = useAppContext()

  if (!selects) return <></>

  return (
    <>
      <div className='content'>
        <Alert
          color='success'
          inversedColors
          message='Demande enregistrée'
          icon='CheckmarkCircle'
        />

        <Recap />
      </div>
    </>
  )
}

export default StepCpo
